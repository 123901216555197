import ko from 'knockout';

ko.bindingHandlers.htmlWithComponents = {
    init: function() {
        return { controlsDescendantBindings: true };
    },
    update: function(element, valueAccessor, allBindings, viewModel, bindingContext) {

        // Insert the HTML
        element.innerHTML = ko.unwrap(valueAccessor());

        // Now apply bindings to descendants
        ko.applyBindingsToDescendants(bindingContext, element);
    }
};